/*
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 21:37:09
 * @LastEditors: v_Yangzhou
 * @LastEditTime: 2021-06-27 00:51:52
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '燃气监测'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '燃气监测'
    }
  },
  {
    path: '/police',
    name: 'police',
    component: () => import('../views/police.vue'),
    meta: {
      title: '报警记录'
    }
  },
  {
    path: '/arm',
    name: 'arm',
    component: () => import('../views/arm.vue'),
    meta: {
      title: '机械臂日志'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
