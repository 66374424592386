/*
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 21:37:09
 * @LastEditors: v_Yangzhou
 * @LastEditTime: 2021-06-27 17:42:41
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 移动端适配
import 'lib-flexible/flexible';
import Vant from 'vant';
import 'vant/lib/index.css';

import axios from './assets/js/http'
Vue.config.productionTip = false
Vue.prototype.http = axios
Vue.use(Vant);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }
  // to 将要访问的路径  from 从哪个路径跳转过来  next 一个函数，表示放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = localStorage.getItem('sessionId')
  if (!tokenStr) return next('/login')
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
