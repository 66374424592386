/*
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-27 17:36:30
 * @LastEditors: v_binqin
 * @LastEditTime: 2021-06-29 21:10:43
 */
//在官方的axios的基础上封装一个添加拦截器的axios
import axios from 'axios'
import { Toast } from 'vant';
import router from '../../router';
//配置默认的路由地址头
axios.defaults.baseURL = 'https://gas.bjsdzk.com/api'
// axios.defaults.baseURL = 'http://47.113.189.134:8081'
// axios.defaults.baseURL = 'http://192.168.0.162:8081'
//全局添加拦截器的作用是可以在每个api前面加上headers的token验证
axios.interceptors.request.use(config => {
  // 判断token是否存在和是否需要token验证的路由
  let id = localStorage.getItem('sessionId')
  if (id) {
    config.headers.Authorization = id;
  }
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0
  });
  return config;
})
axios.interceptors.response.use(function (response) {
  Toast.clear()
  if (response.data.code !== 200) {
    var messages = response.data.message+""
    if (messages != null && messages.indexOf("已存在")!=-1) {
      Toast.fail({ message: messages, allowMultiple: true, type: "html", position: "bottom" });
    } else {
      Toast.fail({ message: messages, allowMultiple: true });
    }
    if(response.data.code == 405){
      localStorage.setItem("sessionId", "");
      router.push('login')
    }
  }
  return response;
}, function (error) {
  Toast.clear()
  Toast.fail(error);
  return Promise.reject(error);
});
export default axios