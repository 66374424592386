<!--
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 21:37:09
 * @LastEditors: v_binqin
 * @LastEditTime: 2021-06-29 21:08:56
-->
<template>
  <div class="home">
    <van-button type="info" v-if="list.length == 0" @click="scanCode"
      >添加设备</van-button
    >
    <div class="infos" v-else>
      <div class="add">
        <van-button type="info" size="small" @click="scanCode"
          >添加设备</van-button
        >
      </div>
      <van-swipe class="my-swipe" :autoplay="0">
        <van-swipe-item v-for="(item, index) in list" :key="item.id">
          <h4>设备信息</h4>
          <ul>
            <li>
              <label>设备ID</label>
              <span>{{ item.deviceId }}</span>
            </li>
            <li>
              <label>设备名称</label>
              <span v-show="!item.editName"
                >{{ item.name }}
                <van-icon name="edit" @click.native="openInput(item, index)"
              /></span>
              <input
                :ref="'input-' + index"
                v-show="item.editName"
                v-model="item.name"
                placeholder="请输入设备名称"
                @blur="modifyName(item)"
              />
            </li>
            <li>
              <label>运行状态</label>
              <span>{{ item.runningStatus }}</span>
            </li>
            <li>
              <label>燃气阀门</label>
              <span class="swith"
                >{{ item.hands == null ? "未知" : item.hands ? "开" : "关"
                }}<van-switch
                  :inactive-value="0"
                  :active-value="1"
                  size="14px"
                  :value="item.hands"
                  @click="selectIndex = index"
                  @input="(checked) => modifySwitch(checked, item)"
                ></van-switch>
              </span>
            </li>
          </ul>
        </van-swipe-item>
      </van-swipe>
      <div class="record">
        <div @click="$router.push('police')">
          <img class="img1" :src="policeImg" alt="" />
          报警记录
        </div>
        <div @click="$router.push('arm')">
          <img class="img2" :src="armImg" alt="" />
          机械臂日志
        </div>
      </div>
    </div>
    <van-popup v-model="show" closeable>
      <div class="popup-box">
        已发送验证码至{{ phone }},请注意查收
        <van-field v-model="sms" placeholder="请输入验证码" />
        <van-button size="small" type="info" @click="sendSwith"
          >确 定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      indicatorColor: "black",
      checked: false,
      show: false,
      list: [],
      selectIndex: 0,
      oldName: "",
      policeImg: require("../assets/2.svg"),
      armImg: require("../assets/1.svg"),
      phone: "",
      sms: "",
      oldItem: "",
      signature: "",
      noncestr: "",
      timestamp: "",
    };
  },
  mounted() {
    // const oScript = document.createElement("script");
    // oScript.type = "text/javascript";
    // oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    // document.body.appendChild(oScript);
    this.initData();
  },
  methods: {
    initData() {
      // this.http
      //   .get("/mp/token/jsapiTicket", {
      //     params: { url: location.href.split("#")[0] }, //location.href.split("#")[0]
      //   })
      //   .then(({ data }) => {
      //     if (data.code == 200) {
      //       this.signature = data.data.signature;
      //       this.noncestr = data.data.noncestr;
      //       this.timestamp = data.data.timestamp;
      //       wx.config({
      //         debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //         appId: "wx256d74865b467a32", // 必填，公众号的唯一标识
      //         timestamp: this.timestamp, // 必填，生成签名的时间戳
      //         nonceStr: this.noncestr, // 必填，生成签名的随机串
      //         signature: this.signature, // 必填，签名
      //         jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
      //       });
      //       // wx.error(function (res) {
      //       //   console.log("我打印的错误日志：：：：：：：" + res);
      //       //   var errorinfo = '';
      //       //   for(var key in res){
      //       //     errorinfo = errorinfo + "属性" + key +"值" +res[key]
      //       //   }
      //       //   alert(errorinfo)
      //       // });
      //     }
      //   });

     let code = this.$route.query.qrresult;
      if (code) {
        this.$dialog
          .confirm({ title: "提示", message: "确认添加该设备？"+code })
          .then(() => {
            // on confirm
           this.http
                    .post("/gas/device/add", {
                      deviceId: code,
                      gasDeviceType:1,
                    })
                    .then(({ data }) => {
                      this.$router.replace({ name: "Home" });
                      if (data.code == 200) {
                        this.$toast.success("设备添加成功");
                        this.timer = setTimeout(() => {
                          //设置延迟执行
                          this.getUserInfo();
                        }, 2000);
                      } else {
                        this.timer = setTimeout(() => {
                          //设置延迟执行
                          this.getUserInfo();
                        }, 2000);
                      }
                    });
          })
          .catch(() => {
            // on cancel
            this.$router.replace({ name: "Home" });
            this.getUserInfo();
          });
      } else {
        this.getUserInfo();
      }
    },
    getUserInfo() {
      this.http.get("/user/me").then(({ data }) => {
        if (data.code == 200) {
          this.phone = data.data.phone;
          localStorage.setItem("companyId", data.data.companyId);
          this.http
            .get("/gas/device/list", {
              params: {
                companyId: data.data.companyId,
              },
            })
            .then(({ data }) => {
              if (data.code == 200) {
                this.list = data.data
                  ? data.data.map((e) => {
                      return {
                        ...e,
                        editName: false,
                      };
                    })
                  : [];
              }
            });
        }
      });
    },
    openInput(item, index) {
      item.editName = true;
      this.oldName = item.name;
      this.$nextTick(() => {
        this.$refs["input-" + index][0].focus();
      });
    },
    modifyName(item) {
      if (item.name == null || item.name == "") {
        this.$toast.fail("设备名称不能为空");
        return;
      }
      item.editName = false;
      this.http
        .put("/gas/device/update", {
          id: item.id,
          name: item.name,
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.$toast.success("设备名称修改成功");
          } else {
            item.name = this.oldName;
          }
        });
    },
    modifySwitch(checked, item) {
      if (item.hands == null) {
        this.$toast.fail("燃气阀门状态未知 不可操作");
        return;
      }
      this.http
        .get("/mp/user/sendCode", {
          params: {
            phone: this.phone,
            type: 2,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.oldItem = item;
            this.show = true;
          }
        });
    },
    sendSwith() {
      if (/\d{6}/.test(this.sms)) {
        this.http
          .get("/gas/mp/switch", {
            params: {
              phone: this.phone,
              deviceId: this.oldItem.deviceId,
              code: this.sms,
              switchOn: !this.oldItem.hands,
            },
          })
          .then(({ data }) => {
            if (data.code == 200) {
              let index = this.list.findIndex((e) => e.id == this.oldItem.id);
              if (index != -1) {
                this.list[index].hands = Number(!this.oldItem.hands);
                this.$toast.success("操作成功");
                this.show = false;
                this.sms = "";
              }
            } else {
              this.sms = "";
              if (data.message == "当前设备处于离线状态") {
                this.show = false;
              }
            }
          })
          .catch((err) => {
            this.show = false;
            this.sms = "";
          });
      } else {
        this.$toast.fail("请输入正确的验证码");
      }
    },
    is_weixn() {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    },
    scanCode() {
      if (!this.is_weixn()) {
        alert("请用微信打开页面，扫码功能仅支持微信页面");
        return;
      }
      sessionStorage.setItem("isInSerach", "1");
      let hrefStr = location.href;
      let href = hrefStr;
      if (hrefStr.indexOf("?") !== -1) {
        href = hrefStr.split("?")[0];
      }
      location.href = `https://www.996315.com/api/scan?redirect_uri=${encodeURIComponent(
        href
      )}`;
      return
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var code = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          console.log("这是我扫描的结果 ：：：：：" + code);
            setTimeout(() => {
              this.$dialog
                .confirm({ title: "提示", message: "确认添加该设备？" })
                .then(() => {
                  // on confirm
                  this.http
                    .post("/gas/device/add", {
                      deviceId: code,
                      gasDeviceType:1,
                    })
                    .then(({ data }) => {
                      this.$router.replace({ name: "Home" });
                      if (data.code == 200) {
                        this.$toast.success("设备添加成功");
                        this.timer = setTimeout(() => {
                          //设置延迟执行
                          this.getUserInfo();
                        }, 2000);
                      } else {
                        this.timer = setTimeout(() => {
                          //设置延迟执行
                          this.getUserInfo();
                        }, 2000);
                      }
                    });
                })
                .catch(() => {
                  // on cancel
                  this.$router.replace({ name: "Home" });
                  this.getUserInfo();
                });
            }, "2000");
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  padding: 10px;
  background: #eee;
  & > .van-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .infos {
    position: relative;
    height: 100%;
    .record {
      position: absolute;
      bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      & > div {
        text-align: center;
        font-size: 16px;
        .img1 {
          margin-bottom: 10px;
          box-sizing: border-box;
          display: block;
          width: 100px;
          padding: 20px;
          border: 1px solid red;
          border-radius: 50%;
        }
        .img2 {
          margin-bottom: 10px;
          box-sizing: border-box;
          display: block;
          width: 100px;
          padding: 20px;
          border: 1px solid rgb(24, 126, 243);
          border-radius: 50%;
        }
      }
    }
  }
  .add {
    text-align: right;
  }
  .my-swipe {
    margin-top: 20px;
  }
  .van-swipe-item {
    box-sizing: border-box;
    padding: 20px;
    background: white;
    border-radius: 20px;
    h4 {
      margin: 0;
      font-size: 18px;
    }
    ul {
      margin-top: 20px;
      li {
        line-height: 60px;
        display: flex;
        align-items: center;
        label {
          display: inline-block;
          margin-right: 20px;
          width: 20%;
          text-align: right;
        }
        & > span {
          display: flex;
          align-items: center;
          font-weight: 500;
          i {
            margin-left: 10px;
            font-size: 14px;
          }
          &.swith {
            flex: 1;
            justify-content: space-between;
          }
        }
        input {
          line-height: 40px;
        }
      }
    }
  }
  .van-popup--center {
    box-sizing: border-box;
    width: 80%;
    padding: 80px 40px 40px;
  }
  .popup-box {
    text-align: center;
    button {
      margin-top: 15px;
    }
  }
}
</style>
